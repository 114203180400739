<template>
  <IAmOverlay
    :loading="isLoading"
    :spinner-variant="'info'"
  >
    <b-card
      v-if="!isMobileView"
      class="border-primary"
      style="z-index: 99"
      body-class="py-50 d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between"
    >
      <template v-if="!isLoading">
        <!-- ANCHOR select tripType -->
        <v-select
          id="tripType"
          v-model="dataSearch.tripType"
          :options="['OW','RT']"
          :calculate-position="withPopper"
          :searchable="false"
          :clearable="false"
        >
          <template #option="data">
            <span class="fw-700 font-medium-1">
              {{ $t(`flight.${data.label}`) }}
            </span>
          </template>
          <template #selected-option="data">
            <div class="d-flex align-items-center gap-1">
              <IAmIcon
                icon="trainIcon"
                size="22"
                class="text-success"
              />
              <span class="fw-700 font-medium-2">
                {{ $t(`flight.${data.label}`) }}
              </span>
            </div>
          </template>
        </v-select>

        <div class="d-inline-flex mx-1">
          <SearchStopPoint :data-prop.sync="dataSearch.departure" />

          <b-button
            v-ripple.100
            variant="white"
            class="btn-icon rounded-circle py-50 px-1"
            @click="swapFromToHandle"
          >
            <!-- <IAmIcon
              icon="swap"
              class="d-flex-center text-white"
              size="20px"
            /> -->
            <img
              src="@icons/swap-horizontal.svg"
              alt="Swap"
            >
          </b-button>

          <SearchStopPoint
            :data-prop.sync="dataSearch.arrival"
            right
          />
        </div>

        <div class="d-flex">
          <SearchDatePicker
            :date.sync="dataSearch.departDate"
            :config="{
              minDate: 'today',
            }"
            class="w-100 fw-800"
            :icon="'@icons/airplane-up.svg'"
            @input="handleDepartDate"
          >
            <template #prefix>
              <IAmIcon
                icon="trainGo"
                size="22"
                class="text-success"
              />
              <span class="mx-50 text-nowrap font-medium-2">{{ $t('train.search.result.departureDate') }}</span>
            </template>
          </SearchDatePicker>
        </div>

        <div
          v-if="dataSearch.tripType === 'RT'"
          class="d-flex"
        >
          <SearchDatePicker
            :date.sync="dataSearch.returnDate"
            :config="{
              minDate: 'today',
            }"
            class="w-100 fw-800"
            :icon="'@icons/airplane-up.svg'"
            @input="handleDepartDate"
          >
            <template #prefix>
              <IAmIcon
                icon="trainBack"
                size="22"
                class="text-success"
              />
              <span class="mx-50 text-nowrap font-medium-2">{{ $t('train.search.result.returnDate') }}</span>
            </template>
          </SearchDatePicker>
        </div>

        <div>
          <b-button
            v-ripple.400
            class="p-75 rounded"
            variant="success"
            :disabled="loading"
            @click="handleSearchButton"
          >
            <div class="d-flex align-items-center">
              <img
                src="@icons/search.svg"
                alt="Search"
                class="ico-bold"
              >
            </div>
          </b-button>
        </div>
      </template>
    </b-card>
  </IAmOverlay>
</template>

<script>
import {
  computed, ref, toRefs,
} from '@vue/composition-api'
import {
  BButton,
  BCard,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { createPopper } from '@popperjs/core'

import IAmOverlay from '@/components/IAmOverlay.vue'

import { convertISODateTime } from '@core/utils/filter'

import useTrainHandle from '@train/useTrainHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    IAmOverlay,
    SearchStopPoint: () => import('./components/SearchStopPoint.vue'),
    SearchDatePicker: () => import('./components/SearchDatePicker.vue'),
    vSelect: () => import('vue-select'),
  },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownWidth = `calc(${Number(width.split('px')[0]) + 50}px )` || width // + 25px
      dropdownList.style.width = dropdownWidth
      dropdownList.style.zIndex = '10000'
      dropdownList.style.translate = `calc((${dropdownWidth} - ${this.right ? '0' : width})/2${this.right ? '*(-1)' : ''})`
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          // {
          //   name: 'offset',
          //   options: {
          //     offset: [0, -1],
          //   },
          // },
          // {
          //   name: 'toggleClass',
          //   enabled: true,
          //   phase: 'write',
          //   fn({ state }) {
          //     component.$el.classList.toggle(
          //       'drop-up',
          //       state.placement === 'top',
          //     )
          //   },
          // },
        ],
      })
      return () => popper.destroy()
    },
  },
  setup(props) {
    const { toastWarning } = useToast()

    const {
      loading,
      searchTrain,
      getBookingCode,
      getSearchPayloadArray,
      handleSetTabIndex,
    } = useTrainHandle()

    const { payload } = toRefs(props)
    const dataSearch = ref(cloneDeep(payload.value))

    const isLoading = computed(() => isEmpty(payload.value))

    function swapFromToHandle() {
      const temp = dataSearch.value.departure
      dataSearch.value.departure = dataSearch.value.arrival
      dataSearch.value.arrival = temp
    }

    function handleSelectTripType(val) {
      dataSearch.value.tripType = val
    }

    function handleDepartDate(date) {
      const departDate = new Date(date)
      if (departDate > new Date(dataSearch.value.returnDate)) {
        dataSearch.value.returnDate = moment.utc(departDate).add(3, 'days').format('YYYY-MM-DD')
      }
    }

    // function modifyDataSearch() {
    //   const { from, to, ...rest } = dataSearch.value
    //   const payload = {
    //     ...rest,
    //     departure: from.code,
    //     arrival: to.code,
    //   }
    //   return JSON.stringify(payload)
    // }

    async function handleSearchButton() {
      const req = { ...dataSearch.value }

      if (['RT'].includes(req.tripType) && !req.returnDate) {
        toastWarning({
          title: 'Vui lòng chọn ngày về !',
        })
        return
      }

      if ([typeof req.departure, typeof req.arrival].includes('object')) {
        req.departure = req.departure?.code || req.departure
        req.arrival = req.arrival?.code || req.arrival
      }

      localStorage.setItem('trainSearch', JSON.stringify(req))

      const reqWithBookingCode = {
        ...req,
        bookingCode: getBookingCode.value || '',
      }

      await searchTrain(reqWithBookingCode)
    }

    return {
      loading,
      isLoading,
      dataSearch,
      getSearchPayloadArray,
      handleSetTabIndex,
      convertISODateTime,
      handleSelectTripType,
      swapFromToHandle,
      handleDepartDate,
      handleSearchButton,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

#tripType ::v-deep {
  min-width: 170px;

  .vs__dropdown-toggle {
    border: solid 1px green;
    height: 45px;
    padding: 0px !important;
  }
  .vs__dropdown-menu {
    min-width: 170px !important;
  }
  .vs__selected-options {
    min-width: 0;
    flex-wrap: nowrap;
    align-items: center;

    .vs__selected {
      min-width: 0;
    }
    .vs__search {
      font-size: 20px;
      font-weight: 600;
      width: 0px !important;
      padding: 0px !important;
    }
  }
  .vs__actions {
    cursor: pointer;
  }
}
#dropdown-type ::v-deep {
  button.dropdown-toggle {
    &::after {
      top: -2px;
    }
  }
  .dropdown-menu {
    z-index: 99;
  }
}
</style>
